import React from 'react'

import { graphql } from 'gatsby'

import SEO from '../components/seo'

import Layout from '../components/layout'

import Projects from '../components/projects'

export default ({ data }) => {
    const { node: category } = data.allWordpressWpProjectCategory.edges[0]
    return (
        <Layout
            breadcrumb={[
                {
                    title: 'Home',
                    link: '/'
                },
                {
                    title: data.allWordpressWpProjectCategory.edges[0].node.name
                }
            ]}
            marketTicker
        >
            <SEO
                title={
                    data.allWordpressWpProjectCategory.edges[0].node.meta_title
                }
                description={
                    data.allWordpressWpProjectCategory.edges[0].node
                        .meta_description
                }
            />
            <div className="pageContainer">
                <div className="wrapper">
                    <div className="row">
                        <div className="col-xs-12">
                            <h1 className="m-b-0">{category.name}</h1>
                        </div>
                    </div>
                </div>
            </div>
            <Projects data={data} />
        </Layout>
    )
}
export const query = graphql`
    query($slug: String!, $id: [Int!]) {
        allWordpressWpProjectCategory(filter: { slug: { eq: $slug } }) {
            edges {
                node {
                    name
                    slug
                    wordpress_id
                    acf {
                        meta_title
                        meta_description
                    }
                }
            }
        }
        allWordpressWpAreas {
            edges {
                node {
                    name
                    slug
                    wordpress_id
                    acf {
                        area_image {
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 900) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        allWordpressWpDevelopers {
            edges {
                node {
                    name
                    slug
                    wordpress_id
                }
            }
        }

        allWordpressWpProject(filter: { project_category: { in: $id } }) {
            edges {
                node {
                    title
                    developers
                    areas
                    project_category
                    slug
                    acf {
                        investor_grade
                        end_user_grade
                    }
                    featured_media {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 900) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
        allWordpressWpSeoSearch {
            edges {
                node {
                    title
                    slug
                    wordpress_id
                    seo_search_category
                    areas
                }
            }
        }
        allWordpressWpSeoSearchCategory {
            edges {
                node {
                    name
                    slug
                    wordpress_id
                }
            }
        }
    }
`
